export const encodeEmailForTelegram = (
  email: string | undefined,
): string | undefined => {
  if (!email) return;
  let result = email.toLowerCase();
  result = result.replace(/\./gi, "fdc3b1");
  result = result.replace(/@/gi, "588d31");
  return result;
};

export const fillTgLinkEmail = (link: string): string => {
  const user = JSON.parse(String(localStorage.getItem("user")));
  return !user?.email
    ? link
    : `${link}?start=${encodeEmailForTelegram(user.email)}`;
};

export const getUrlSlugWithoutLocale = (slug: string): string => {
  const countryCodeLength = 2;
  const urlSlugStartWithoutCountryCode = 3;

  return slug.split("-")[0].length === countryCodeLength
    ? slug.slice(urlSlugStartWithoutCountryCode)
    : slug;
};

export const isAppleDevice = () => {
  const headers = useRequestHeaders();
  const userAgent = headers["user-agent"] ?? navigator.userAgent;
  const appleDevices = /(iPhone|iPad|iPod|Macintosh|Mac\sOS)/i;

  return appleDevices.test(userAgent);
};
